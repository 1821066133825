#dashboard-quick-links-ui {
  li {
    list-style: none;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    a {
      color: #006c93;
      text-decoration: none;
    }
  }
}
