article {
  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1rem !important;
  }
}
.announcements-wrapper {
  @media (max-width: 768px) {
    box-shadow: none !important;
  }
  a {
    color: #077a9e;
    padding: 0 0 2.5rem 2.5rem;
    @media (max-width: 768px) {
      display: none !important;
    }
  }
}
.announcements-divider {
  &:after {
    content: '';
    display: block;
    margin: 0;
    width: 80%;
    padding-left: 2rem;
    border-bottom: 1px solid #e1e1e1;
    @media (max-width: 768px) {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
}
