.time-clock {
  a {
    text-decoration: none;
    h1 {
      font-weight: 500;
      color: #006c93;
      font-size: 1.125rem;
    }
  }
  .stopwatch-icon {
    color: '#A7DCEC';
    font-size: 2rem;

    --fa-primary-color: #057fa8;
    --fa-secondary-color: #8ac7db;
    --fa-secondary-opacity: 1;
  }
  .external-link-icon {
    color: #006c93;
  }
}
